export const getMatchingExperiences = (experiences, searchQuery) => {
  const sanitisedSearchQuery = searchQuery.toLowerCase();

  return experiences.filter(({ title }) => title && title.toLowerCase().includes(sanitisedSearchQuery));
};

export const getMatchingPrograms = (programs, searchQuery) => {
  const sanitisedSearchQuery = searchQuery.toLowerCase();

  return programs.filter(({ title, description }) => (
    (title && title.toLowerCase().includes(sanitisedSearchQuery)) ||
    (description && description.toLowerCase().includes(sanitisedSearchQuery))
  ));
};
