import { shape, string } from 'prop-types';
import React, { useCallback, useState, useEffect } from 'react';
import {
  ModularPage
} from '@paddl/storybook';
import {
  MembersDirectoryModal,
  Metadata,
  ProgramPageContent,
  ProgramPageSidebarContent,
  useResource,
  MemberAppNavigation_Populated,
  MemberAppNavigation_Populated_NEON
} from '@paddl/utils-react';
import {
  hardRedirect, isToggleEnabled
} from '@paddl/utils-js';

const breadcrumbItems = [
  {
    title: 'Explore',
    action: () => {
      hardRedirect('explore');
    }
  },
  { title: 'Program' }
];

export const ProgramPage = ({
  match: {
    params: { programId }
  }
}) => {
  const [isProfileDirectoryOpen, setIsProfileDirectoryOpen] = useState(false);
  const openProfileDirectory = useCallback(() => {
    setIsProfileDirectoryOpen(true);
  }, []);

  const { data: programData } = useResource(`${process.env.API_PREFIX_AT_WORK}/at-work/program/${programId}`);

  const program = programData?.program;

  useEffect(() => {
    if (window?.analytics && programData?.program) {
      window.analytics.page(
        'Explore App',
        'Program',
        { programId, programTitle: programData.program.title, providerId: programData.program.providerId });
    }
  }, [programData, programId]);

  return <div>
  <Metadata title={`Program ${program?.title || ''} | Paddl`} />
  <ModularPage
    breadcrumbItems={breadcrumbItems}
    navigationContent={isToggleEnabled('neon')
      ? <MemberAppNavigation_Populated_NEON currentPage="explore" />
      : <MemberAppNavigation_Populated currentPage="explore" />}
    pageName="Experience"
    pageContent={program ? <ProgramPageContent
      program={program}
      openProfileDirectory={openProfileDirectory}
      breadcrumbItems={breadcrumbItems}
      pageBackground="dark"
    /> : null}
    sidebarContent={program ? <ProgramPageSidebarContent program={program} openProfileDirectory={openProfileDirectory} /> : null}
  />
  {isProfileDirectoryOpen && <MembersDirectoryModal
    isModalOpen={isProfileDirectoryOpen}
    programId={programId}
    onClose={() => setIsProfileDirectoryOpen(false)}
  />}
         </div>;
};

ProgramPage.propTypes = {
  match: shape({
    params: shape({
      programId: string.isRequired
    }).isRequired
  }).isRequired
};
