import styled from '@emotion/styled';

import {
  Artworks,
  Button,
  Colors,
  FeedFooter,
  Fonts,
  InnerContentWrapper,
  MainContentWrapper,
  ModularPage,
  PageColumn
} from '@paddl/storybook';
import {
  getDecodedUserSessionCookie, hardRedirect,
  isToggleEnabled,
  packagePrefixes
} from '@paddl/utils-js';
import {
  MemberAppNavigation_Populated,
  MemberAppNavigation_Populated_NEON,
  Metadata,
  useResource
} from '@paddl/utils-react';
import React, { useEffect, useState } from 'react';
import { ProgramSection } from '../components/ProgramSection';
import { SearchBar } from '../components/SearchBar';
import { SearchResults } from '../components/SearchResults';

import { ExperienceSection } from '../components/ExperienceSection';
import { ProgramSection_NEON } from '../components/ProgramSection_NEON';
import { XpCollection } from '../components/XpCollection';

const breadcrumbItems = [{ title: 'Explore' }];

const Header = styled.div`
    ${Fonts.H3}
    padding-bottom: 8px;
`;

const HeaderContainer = styled.div`
    padding-bottom: 16px;
    border-bottom: 1px solid ${Colors.grey_lighter};
`;

const Subheader = styled.div`
    ${Fonts.body}
`;

function EmptyState() {
  return (
    <div style={{
      display: 'flex', flexDirection: 'column', gap: '24px', alignItems: 'center', padding: '32px 0px'
    }}
    >
      <Artworks.noExperiences />
      <div style={{ textAlign: 'center' }}>
        <Header>No Experiences Available</Header>
        <Subheader>It appears you don’t have any access to Paddl upskilling content.</Subheader>
      </div>
      <Button onClick={() => hardRedirect('newActivities')} variant="contained" color="purple">Track your Upskilling</Button>
    </div>
  );
}

export function _ExplorePage() {
  const urlPrefix = isToggleEnabled('neon') ? `${process.env.AT_WORK_API}/v2` : `${process.env.API_PREFIX_AT_WORK}`;
  const { isLoading, data: experiencesData } = useResource(`${urlPrefix}/explore/experiences/on-demand/summary`, isToggleEnabled('neon'));
  const { isLoading: isLearnSomethingNewLoading, data: learnSomethingNewData } = useResource(`${urlPrefix}/explore/learn-something-new?limit=12`);
  const { isLoading: isGoDeeperLoading, data: goDeeperData } = useResource(`${urlPrefix}/explore/go-deeper?limit=12`);
  const { data: completionData } = useResource(`${process.env.API_PREFIX_AT_WORK}/at-work/experiences/complete`, isToggleEnabled('neon'));
  const [searchQuery, setSearchQuery] = useState('');
  const { data: programsData, isLoading: isProgramsLoading } = useResource(`${urlPrefix}/explore/programs`);

  const completedExperiences = completionData?.completedExperiences || [];

  useEffect(() => {
    if (window?.analytics) {
      window.analytics.page('Explore App', 'Explore');
    }
  }, []);

  useEffect(() => window.scrollTo(0, 0), []);

  const { profile_id: profileId } = getDecodedUserSessionCookie();

  return (
      <MainContentWrapper colorVariant="dark" withGhostNav>
      {profileId && <XpCollection profileId={profileId} />}
      <Metadata title="Explore Experiences | Paddl" />
      <InnerContentWrapper size="large">
        <PageColumn>
        <HeaderContainer>
          <SearchBar onSearch={setSearchQuery} />
        </HeaderContainer>
        {!isLoading && experiencesData?.experiences?.length === 0 && <EmptyState />}
        {!isLoading && searchQuery &&
          <SearchResults
            searchQuery={searchQuery}
            completedExperiences={completedExperiences}
            experiences={experiencesData?.experiences || []}
            programs={programsData?.programs || []}
          />}
        {!searchQuery &&
          <ExperienceSection
            experiences={learnSomethingNewData?.experiences || []}
            isLoading={isLearnSomethingNewLoading}
            totalCount={learnSomethingNewData?.totalCount}
            title="Learn Something New"
            showAllLink={`${packagePrefixes[process.env.NODE_ENV].exploreApp}/explore/learn-something-new`}
          />}
        {!searchQuery &&
          <ExperienceSection
            experiences={goDeeperData?.experiences || []}
            isLoading={isGoDeeperLoading}
            totalCount={goDeeperData?.totalCount}
            title="Go Deeper"
            showAllLink={`${packagePrefixes[process.env.NODE_ENV].exploreApp}/explore/go-deeper`}
          />}

        {!isToggleEnabled('neon') && !searchQuery && <ProgramSection programs={programsData?.programs || []} isLoading={isProgramsLoading} /> }
        {isToggleEnabled('neon') && !searchQuery && <ProgramSection_NEON programs={programsData?.programs || []} isLoading={isProgramsLoading} /> }

        <FeedFooter />
        </PageColumn>
      </InnerContentWrapper>
      </MainContentWrapper>
  );
}

export const ExplorePage = () => <ModularPage
  navigationContent={isToggleEnabled('neon')
    ? <MemberAppNavigation_Populated_NEON currentPage="explore" />
    : <MemberAppNavigation_Populated currentPage="explore" />}
  breadcrumbItems={breadcrumbItems}
  pageName="explore"
  pageContent={<_ExplorePage />}

/>;
