import Grid from '@mui/material/Grid';

import {
  FeedFooter,
  MainContentWrapper,
  InnerContentWrapper,
  ExperienceCardSkeleton,
  SectionHeader,
  Section,
  PageColumn,
  ModularPage
} from '@paddl/storybook';
import { hardRedirect, packagePrefixes, isToggleEnabled } from '@paddl/utils-js';
import {
  Metadata,
  OnDemandExperienceSummaryCard,
  useResource,
  MemberAppNavigation_Populated,
  MemberAppNavigation_Populated_NEON
} from '@paddl/utils-react';
import React, { useEffect, useMemo } from 'react';

const breadcrumbItems = [
  {
    title: 'Explore',
    action: () => {
      hardRedirect('explore');
    }
  }, { title: 'Learn Something New' }];

export const _LearnSomethingNewPage = () => {
  const { isLoading, data: learnSomethingNewData } = useResource(`${process.env.API_PREFIX_AT_WORK}/explore/learn-something-new`);

  useEffect(() => {
    if (window?.analytics) {
      window.analytics.page('Explore App', 'Learn Something New');
    }
  }, []);

  const gridItems = useMemo(() => {
    if (isLoading) {
      return [<ExperienceCardSkeleton key={1} />, <ExperienceCardSkeleton key={2} />, <ExperienceCardSkeleton key={3} />];
    }

    return (learnSomethingNewData?.experiences || []).map(({
      id, title, providerId, activityType, duration
    }) =>
            <OnDemandExperienceSummaryCard
              key={id}
              id={id}
              title={title}
              handleSeeMore={() => { window.location.href = `${packagePrefixes[process.env.NODE_ENV].exploreApp}/explore/experience/${id}`; }}
              providerId={providerId}
              activityType={activityType}
              duration={duration}
            />
    );
  }, [isLoading, learnSomethingNewData]);

  return (
    <MainContentWrapper colorVariant="dark" withGhostNav>
            <Metadata title="Explore Experiences | Paddl" />
            <InnerContentWrapper size="large">
              <PageColumn>
                <Section>
                <SectionHeader>Learn something new</SectionHeader>
                <Grid container spacing={2}>
                    {gridItems.map((item) =>
                        <Grid key={item.key} item xs={12} sm={6} md={6} lg={4}>
                            {item}
                        </Grid>)};
                </Grid>
                </Section>

                <FeedFooter />
              </PageColumn>
            </InnerContentWrapper>
    </MainContentWrapper>
  );
};

export const LearnSomethingNewPage = () => <ModularPage
  navigationContent={isToggleEnabled('neon')
    ? <MemberAppNavigation_Populated_NEON currentPage="explore" />
    : <MemberAppNavigation_Populated currentPage="explore" />}
  breadcrumbItems={breadcrumbItems}
  pageName="explore"
  pageContent={<_LearnSomethingNewPage />}

/>;
