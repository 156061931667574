import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Colors, LabelTextMedium } from '@paddl/storybook';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import { func } from 'prop-types';
import { IconButton } from '@mui/material';

const Container = styled.div`
  display: flex;
  height: 56px;
  padding: 16px 24px;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  background-color: white;
  box-sizing: border-box;
  width: 100%;
  border-radius: 32px;
  border: 1px solid ${Colors.new_grey_lighter};

  svg {
    fill: ${Colors.new_grey_light};
  }
`;

const StyledIconButton = styled(IconButton)`
  height: 32px;
  width: 32px;
  svg {
    fill: ${Colors.new_grey_light};
  }

  :disabled {
    background: none !important;
    svg {
      fill: ${Colors.new_grey_lighter};
    }
  }
`;

const AccessibilityLabel = styled.label`
  visibility: hidden;
  position: absolute;
`;

const InputField = styled(LabelTextMedium)`
  font-family: inherit;
  border: none;
  width: 100%;
  height: 48px;

  ::placeholder {
    color: ${Colors.new_grey_light};
  }
`;

export const SearchBar = ({ onSearch }) => {
  const [query, setQuery] = useState('');

  const _onChange = (event) => {
    setQuery(event.currentTarget.value);
  };

  const _onSubmit = (event) => {
    event.preventDefault();
    onSearch(query);
  };

  const _onClear = () => {
    setQuery('');
    onSearch('');
  };

  return (
  <Container>
    <SearchSharpIcon />
    <form onSubmit={_onSubmit} style={{ flex: 1 }} data-testid="search-form">
    <AccessibilityLabel for="search">Search</AccessibilityLabel>
    <InputField as="input" id="search" placeholder="Search" type="text" value={query} onChange={_onChange} />
    </form>

    <StyledIconButton
      disabled={!query}
      size="small"
      title="clear search"
      onClick={_onClear}
    ><CloseSharpIcon />
    </StyledIconButton>
  </Container>);
};

SearchBar.propTypes = {
  onSearch: func.isRequired
};
