import {
  ProgramCardWithAvatars_NEON
} from '@paddl/utils-react';
import {
  arrayOf,
  shape,
  bool
} from 'prop-types';
import React from 'react';
import {
  ProgramCardSkeleton,
  CarouselHeader,
  Carousel
} from '@paddl/storybook';
import { packagePrefixes } from '@paddl/utils-js';

export const ProgramSection_NEON = ({ programs, isLoading }) => {
  if (!isLoading && programs.length === 0) return null;

  const carouselItems = isLoading ? [<ProgramCardSkeleton />]
    : programs
      .sort(() => Math.random() - 0.5)
      .map((
        program
      ) => {
        return (
      <ProgramCardWithAvatars_NEON
        key={program.id}
        title={program.title}
        hero={program.hero}
        id={program.id}
        description={program.description}
      />
        );
      });

  return <Carousel
    items={carouselItems}
    header={<CarouselHeader.ShowAll title="Programs" totalCount={programs.length} showAllUrl={`${packagePrefixes[process.env.NODE_ENV].exploreApp}/explore/programs`} />}
    maxNumberToDisplay={1}
  />;
};

ProgramSection_NEON.defaultProps = {
  isLoading: false
};

ProgramSection_NEON.propTypes = {
  programs: arrayOf(shape({})).isRequired,
  isLoading: bool
};
