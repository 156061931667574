import { hardRedirect } from '@paddl/utils-js';
import { ExplorePage } from '../pages/ExplorePage';
import { ExperiencePage } from '../pages/ExperiencePage';
import { ProgramPage } from '../pages/ProgramPage';
import { LearnSomethingNewPage } from '../pages/LearnSomethingNewPage';
import { GoDeeperPage } from '../pages/GoDeeperPage';
import { AllProgramsPage } from '../pages/AllProgramsPage';

export const routerConfig = [
  {
    path: '/explore/experience/:experienceId',
    component: ExperiencePage
  },
  {
    path: '/explore/program/:programId/experience/:experienceId',
    component: ExperiencePage
  },
  {
    path: '/explore/program/:programId',
    component: ProgramPage
  },
  {
    path: '/explore/learn-something-new',
    component: LearnSomethingNewPage
  },
  {
    path: '/explore/go-deeper',
    component: GoDeeperPage
  },
  {
    path: '/explore/programs',
    component: AllProgramsPage
  },
  {
    path: '/explore',
    component: ExplorePage
  },
  {
    path: '/',
    component: () => {
      hardRedirect('explore');

      return null;
    }
  }

];
