import { BaselineStylesProvider } from '@paddl/storybook';
import { GlobalTypography } from '@paddl/storybook/lib/Foundation/Fonts/GlobalTypography';
import { initialiseBrowserErrorTracking } from '@paddl/utils-js';
import { Auth0ProviderWithHistory, LoginGate, FeatureToggleProvider } from '@paddl/utils-react';
import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import { RouterWrapper } from './router/Router';

initialiseBrowserErrorTracking({
  applicationName: process.env.PACKAGE_NAME,
  sentryDsn: process.env.SENTRY_DSN,
  nodeEnv: process.env.NODE_ENV
});

ReactDOM.render(
    <BaselineStylesProvider>
    <GlobalTypography />
    <FeatureToggleProvider>
      <Router>
        <Auth0ProviderWithHistory>
          <LoginGate>
            <Route component={RouterWrapper} />
          </LoginGate>
        </Auth0ProviderWithHistory>
      </Router>
    </FeatureToggleProvider>
    </BaselineStylesProvider>,
    document.getElementById('root')
);
