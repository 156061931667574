import {
  ExperienceCardSkeleton,
  CarouselHeader,
  Carousel
} from '@paddl/storybook';
import {
  OnDemandExperienceSummaryCard
} from '@paddl/utils-react';
import {
  arrayOf,
  bool,
  shape,
  number,
  string
} from 'prop-types';
import React, { useMemo } from 'react';

export const ExperienceSection = ({
  experiences, isLoading, totalCount, title, showAllLink
}) => {
  const carouselHeader = <CarouselHeader.ShowAll
    title={title}
    showAllUrl={showAllLink}
    totalCount={totalCount}
  />;

  const carouselItems = useMemo(() => {
    if (isLoading) {
      return [<ExperienceCardSkeleton key={1} />, <ExperienceCardSkeleton key={2} />, <ExperienceCardSkeleton key={3} />];
    }

    return experiences.map(({
      id, title: experienceTitle, providerId, activityType, duration
    }) =>
          <OnDemandExperienceSummaryCard
            key={id}
            id={id}
            title={experienceTitle}
            handleSeeMore={() => { window.location.href = `${window.location.href}/experience/${id}`; }}
            providerId={providerId}
            activityType={activityType}
            duration={duration}
            isComplete={false}
          />);
  }, [experiences, isLoading]);

  if (!isLoading && experiences.length === 0) {
    return null;
  }

  return <Carousel header={carouselHeader} items={carouselItems} />;
};

ExperienceSection.defaultProps = {
  isLoading: false,
  totalCount: 0,
  title: '',
  showAllLink: ''
};

ExperienceSection.propTypes = {
  isLoading: bool,
  experiences: arrayOf(shape({})).isRequired,
  totalCount: number,
  title: string,
  showAllLink: string
};
