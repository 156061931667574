import {
  ProgramCardWithAvatars
} from '@paddl/utils-react';
import {
  arrayOf,
  shape,
  bool
} from 'prop-types';
import React from 'react';
import {
  ProgramCardSkeleton,
  CarouselHeader,
  Carousel
} from '@paddl/storybook';
import { packagePrefixes } from '@paddl/utils-js';

export const ProgramSection = ({ programs, isLoading }) => {
  if (!isLoading && programs.length === 0) return null;

  const carouselItems = isLoading ? [<ProgramCardSkeleton />]
    : programs
      .sort(() => Math.random() - 0.5)
      .map((
        program
      ) => {
        return (
      <ProgramCardWithAvatars
        data-test-id={`program-card-${program.id}`}
        key={program.id}
        title={program.title}
        hero={program.hero}
        id={program.id}
        description={program.description}
        numberOfProfiles={program.profilesInProgram.numberOfProfiles}
        profilePreviews={program.profilesInProgram.profilePreviews}
      />
        );
      });

  return <Carousel
    items={carouselItems}
    header={<CarouselHeader.ShowAll title="Programs" totalCount={programs.length} showAllUrl={`${packagePrefixes[process.env.NODE_ENV].exploreApp}/explore/programs`} />}
    maxNumberToDisplay={1}
  />;
};

ProgramSection.defaultProps = {
  isLoading: false
};

ProgramSection.propTypes = {
  programs: arrayOf(shape({})).isRequired,
  isLoading: bool
};
