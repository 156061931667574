import {
  FeedFooter,
  InnerContentWrapper,
  MainContentWrapper,
  PageColumn,
  ModularPage
} from '@paddl/storybook';
import { hardRedirect, isToggleEnabled } from '@paddl/utils-js';
import {
  Metadata,
  useResource,
  AllProgramsSection,
  MemberAppNavigation_Populated,
  MemberAppNavigation_Populated_NEON
} from '@paddl/utils-react';
import React, { useEffect } from 'react';

const breadcrumbItems = [
  {
    title: 'Explore',
    action: () => {
      hardRedirect('explore');
    }
  }, { title: 'Programs' }];

export const _AllProgramsPage = () => {
  const urlPrefix = isToggleEnabled('neon') ? `${process.env.AT_WORK_API}/v2` : `${process.env.API_PREFIX_AT_WORK}`;
  const { data: programsData, isLoading } = useResource(`${urlPrefix}/explore/programs`);

  useEffect(() => {
    if (window?.analytics) {
      window.analytics.page('Explore App', 'All Programs');
    }
  }, []);

  return (
    <MainContentWrapper colorVariant="dark" withGhostNav>
            <Metadata title="Explore Experiences | Paddl" />
            <InnerContentWrapper size="large">
              <PageColumn>
                <AllProgramsSection programs={programsData?.programs} isLoading={isLoading} />
                <FeedFooter />
              </PageColumn>
            </InnerContentWrapper>
    </MainContentWrapper>
  );
};

export const AllProgramsPage = () => <ModularPage
  navigationContent={isToggleEnabled('neon')
    ? <MemberAppNavigation_Populated_NEON currentPage="explore" />
    : <MemberAppNavigation_Populated currentPage="explore" />}
  breadcrumbItems={breadcrumbItems}
  pageName="Explore - All Programs"
  pageContent={<_AllProgramsPage />}

/>;
