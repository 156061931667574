import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { SnackbarFeedback, Icons } from '@paddl/storybook';
import {
  number,
  shape,
  func,
  arrayOf,
  oneOfType
} from 'prop-types';
import {
  withDataFetching,
  withDataSubmission
} from '@paddl/utils-react';

const COLLECT_XP_SNACK_BARS_INITIAL_STATE = {
  canCollectCards: false,
  collectedCards: false
};

const hasCardWithoutCoolDown = (cardsResponse) =>
  cardsResponse.filter(({ cooldownLeft }) => cooldownLeft === 0).length;

export const XpCollectionComp = ({
  profileId,
  collectXp: { submit, response: postRequestResponse },
  getCards: { response: cardsResponse, isSuccess: hasCards, fetch: fetchCards }
}) => {
  const [openSnackBars, setOpenSnackBars] = useState(COLLECT_XP_SNACK_BARS_INITIAL_STATE);

  const setOpenSnackBar = (snackBarName) =>
    setOpenSnackBars({ ...COLLECT_XP_SNACK_BARS_INITIAL_STATE, [snackBarName]: true });

  useEffect(() => {
    if (profileId) {
      fetchCards(`${process.env.API_PREFIX_V2}/basecamp/cards/${profileId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const XpIcon = Icons.xp;

  useEffect(() => {
    if (hasCards && cardsResponse.length) {
      const canCollectCards  = hasCardWithoutCoolDown(cardsResponse);

      if (canCollectCards) {
        setOpenSnackBar('canCollectCards');
      }
    }
  }, [cardsResponse, hasCards]);

  useEffect(() => {
    if (postRequestResponse?.xpNumber !== undefined) {
      setOpenSnackBar('collectedCards');
    }
  }, [postRequestResponse]);

  return (
    <>
      <SnackbarFeedback
        hasTime={false}
        hasCloseButton={false}
        data-test-id="you-have-xp-available"
        type="infoAlternative"
        isOpen={openSnackBars.canCollectCards}
        action={() => submit(`${process.env.API_PREFIX_V2}/basecamp/cards/collect-all-xp`, {})}
        buttonText="Collect XP ⚡️"
        message={
          <>
            <XpIcon />
            <span>You have XP available!</span>
          </>
        }
      />
      <SnackbarFeedback
        hasTime
        type="success"
        isOpen={openSnackBars.collectedCards}
        message={
          <span>🎉 You collected {postRequestResponse?.xpNumber} XP!</span>
        }
      />
    </>
  );
};

XpCollectionComp.propTypes = {
  profileId: number.isRequired,
  collectXp: shape({
    submit: func.isRequired,
    response: shape({}).isRequired
  }).isRequired,
  getCards: shape({
    response: oneOfType([shape({}), arrayOf(shape({}))]),
    fetch: func.isRequired
  }).isRequired
};

export const XpCollection = compose(
  withDataSubmission({ propNameWrapper: 'collectXp' }),
  withDataFetching({ propNameWrapper: 'getCards' })
)(XpCollectionComp);
